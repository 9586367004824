import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";

import styles from "./index.module.css";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={["utah foster care", "foster care"]} />
    <h1 className={styles.headerText}>
      Utah Foster Care <br />
      Policies and Procedures
    </h1>
    <p>
      This course is designed to help you understand the policies and procedures
      you will need to know as a foster parent. Some portions of it are video
      and other portions are interactive activites.
    </p>
    <Header renderInIndex />
  </Layout>
);

export default IndexPage;
